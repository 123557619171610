<template>
	<div class="record_box">
		<div class="record">
			<div class="title">{{ $t('announcement.title') }}</div>
			<div class="a_body">
				<div class="a_item" v-for="(item, index) in tableData.value" :key="index">
					<div class="a_title">{{ item.noticeTitle }}</div>
					<div class="a_date">
						<img src="../../assets/wallet/number_icon1.png" alt="" />
						{{ item.createTime }}
					</div>
					<div class="a_content" v-html="item.noticeContent"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { ElMessage } from 'element-plus';
import { noticeList } from '@/api/index';

const router = useRouter();
let state = reactive({
	input1: 'GJDKIOQMME',
	input2: 'https://www.coin.com/zh-hant/assets/coin',
	dialogTableVisible: false,
});

const tableData = reactive([]);
// api 请求
noticeList()
	.then((res) => {
		// 关闭加载
		if (res.code == 200) {
			console.log(res.data);
			tableData.value = res.data;
			console.log(tableData);
		} else {
			ElMessage.error(res.msg);
		}
	})
	.catch(() => {
		// 关闭加载
	});
</script>

<style scoped lang="scss">
/* 当前屏幕大于768px 应用媒体查询里面的样式 */
@media screen and (min-width: 768px) {
	.record_box {
		width: 70vw;
		min-height: 1288px;
		margin: 0 0 0 15px;
		display: flex;
		flex-direction: column;
		.record {
			width: 100%;
			background: #ffffff;
			margin-bottom: 15px;
			border-radius: 7px;
			border: 1px solid #e4e4e4;
			.title {
				border-bottom: 1px solid #e4e4e4;
				font-size: 25px;
				color: #333333;
				font-weight: 600;
				height: 72px;
				padding-left: 25px;
				display: flex;
				align-items: center;
			}
			.a_body {
				// height: 202px;
				padding: 40px;
				display: flex;
				flex-direction: column;
				align-items: center;
				.a_item {
					display: flex;
					flex-direction: column;
					width: 100%;
					// width: 948px;
					// height: 183px;
					background: #f6f8fc;
					border-radius: 10px;
					padding: 20px 30px;
					font-size: 16px;
					color: #666666;
					margin-bottom: 20px;
					.a_title {
						font-weight: 600;
						font-size: 18px;
						color: #333333;
						margin-bottom: 15px;
					}
					.a_date {
						display: flex;
						align-items: center;
						margin-bottom: 15px;
						img {
							width: 16px;
							height: 16px;
							margin-right: 5px;
						}
					}
				}
			}
		}
	}
}
@media screen and (max-width: 768px) {
}
</style>

<style lang="scss">
.record_box {
	.el-table th.el-table__cell {
		background: #f6f8fc !important;
		color: #4a4a4a;
	}
}
</style>

