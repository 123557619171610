<template>
	<div class="announ_box">
		<div class="announ">
			<div class="title">{{$t('my_buy_history.title')}}</div>
			<div class="a_body">
				<el-table :data="tableData" :border="true" style="width: 100%">
					<el-table-column prop="remark" :label="$t('my_buy_history.label1')" width="308" />
					<!-- <el-table-column prop="remark" label="数量"/> -->
					<el-table-column prop="amount" :label="$t('my_buy_history.label2')" />
					<!-- <el-table-column prop="type" label="卡片类型" width="100" /> -->
					<!-- <el-table-column prop="zip" label="交易状态" width="100" >
						<template #default="scope">
								<span v-if="locale == 'zh'">已到账</span>
								<span v-else>Payment has been received</span>
						</template>
					</el-table-column> -->
					<el-table-column prop="createTime" :label="$t('my_buy_history.label3')" width="308" />
				</el-table>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { ElMessage } from 'element-plus';
import { buyHistory } from '@/api/index';
const router = useRouter();
let state = reactive({});

const tableData = ref([]);
// api 请求
buyHistory()
	.then((res) => {
		// 关闭加载
		if (res.code == 200) {
			// console.log(res.data);
			tableData.value = res.data;
		} else {
			ElMessage.error(res.msg);
		}
	})
	.catch(() => {
		// 关闭加载
	});
</script>

<style scoped lang="scss">
/* 当前屏幕大于768px 应用媒体查询里面的样式 */
@media screen and (min-width: 768px) {
	.announ_box {
		width: 70vw;
		min-height: 1288px;
		margin: 0 0 0 15px;
		display: flex;
		flex-direction: column;
		.announ {
			width: 100%;
			background: #ffffff;
			margin-bottom: 15px;
			border-radius: 7px;
			border: 1px solid #e4e4e4;
			.title {
				border-bottom: 1px solid #e4e4e4;
				font-size: 25px;
				color: #333333;
				font-weight: 600;
				height: 72px;
				padding-left: 25px;
				display: flex;
				align-items: center;
			}
			.a_body {
				// height: 202px;
				padding: 40px;
				display: flex;
				align-items: center;
				.w_left {
					display: flex;
					flex-direction: column;
					margin-right: 310px;
					.name {
						font-size: 22px;
						color: #4a4a4a;
						margin-bottom: 30px;
					}
					.number {
						font-size: 30px;
						span {
							font-size: 45px;
							color: #333333;
						}
					}
				}
				.w_right {
					display: flex;
					align-items: center;
					.w_item {
						display: flex;
						flex-direction: column;
						margin-right: 68px;
						font-size: 25px;
						align-items: center;
						color: #333333;
						font-weight: 600;
						img {
							width: 70px;
							margin-bottom: 10px;
						}
					}
				}
			}
		}
	}
}
@media screen and (max-width: 768px) {
}
</style>

<style lang="scss">
.announ_box {
	.el-table th.el-table__cell {
		background: #f6f8fc !important;
		color: #4a4a4a;
	}
}
</style>
